body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  position: fixed;
  left: 50px;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}

.left-element {
  background: #ffffff;
  display: inline-block;
  float: left;
}

.right-element {
  background: #ffffff;
  display: inline-block;
  float: left;
  white-space: pre-wrap;
  word-break: break-all;
  min-width: 300px;
  max-width: 1200px;
}

.parse-margin {
  max-width: 400px;
  white-space: normal;
}

.rat-nest {
  border-radius: 25px;
  background: #5e99a8;
  padding: 20px;
  width: 200px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}